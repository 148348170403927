import React, { useState } from "react";
import { Navbar } from "../Navbar";
import SessionTypeList from "../SessionType/SessionTypeList";
import { Autocomplete, Avatar, Box, Container, TextField } from "@mui/material";
import CoachList from "../Coach/CoachList";
import SessionList from "../Sessions/SessionList";
import Vmax from "../../assets/VMax ShapeUp Studio Logo.png";
import { Button } from "@mui/material";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { axiosFetch } from "../../Utils/AxiosFetch";
import { useSelectedCountry } from "../../store/SelectedCountryContext";
import { countries } from "../../Utils/countries";
import "../../components/commonStyle.css";
import { useTabState } from "../../store/TabContext";

const sortedCountryCodes = countries.sort((a, b) =>
  a.label.localeCompare(b.label)
);

export default function Homepage() {
  const [tabValue, setTabValue] = useState(1);

 

  const { state, setState, timeDetails, setTImeDetails } = useTabState();


  const defaultTimeZone =timeDetails.timezone?timeDetails.timezone: "Asia/Kolkata";
  

  const { selectedCountry, setSelectedCountry } = useSelectedCountry();

  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = async (event, newValue) => {
    console.log("event", event, newValue);

    const CountryValur = newValue ? newValue.timezone : defaultTimeZone;

    setSelectedCountry(CountryValur);
    const countryValue = await countries.find(
      (val) => val.timezone === CountryValur
    );

    console.log(countryValue, "contryValue");

    setSearchQuery("");

    setTImeDetails(countryValue);
  };

  const toggleTab = (param) => {
    setTabValue(param);
  };

  const handleSignOut = async () => {
    try {
      const response = await axiosFetch({
        url: "/leadSignout",
        method: "post",
      });

      if (response.status === 200) {
        localStorage.removeItem("authTocken");
        window.location.reload();
      }
    } catch {}
  };

  //const isAndroid = navigator.userAgent.toLowerCase().includes("android");
  const isChrome = navigator.userAgent.toLowerCase().includes("chrome");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    countries.filter((val) => val.timezone == event.target.value);
  };

  const filteredCountries = sortedCountryCodes.filter((country) => {
    const searchLower = searchQuery != "" && searchQuery.toLowerCase();

    console.log(searchLower, "searchLower");
    return (
      country?.code.toLowerCase().includes(searchLower) ||
      country?.timezone.toLowerCase().includes(searchLower)
    );
  });

  // console.log(filteredCountries, "searchQuerysearchQuerysearchQuery");

  return (
    <Container sx={{ overflow: "hidden", padding: "0px" }}>
      <Box>
        <div
          className="header-logo-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            flexDirection: { xs: "column", sm: "row" },
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          {/* Logo container with extra space for mobile */}
          <div
            style={{ width: "80px", marginBottom: { xs: "20px", sm: "0px" } }}
          >
            <img src={Vmax} alt="logo" style={{ width: "100%" }} />
          </div>

          {/* Autocomplete input with scrollable options */}
          <Autocomplete
            id="country-select-demo"
            sx={{
              width: { xs: "65%", sm:250 },
              marginTop: { xs: "10px", sm: "0px" },
            }}
            options={countries}
            value={timeDetails != undefined ?timeDetails : {label:"Asia/Kolkata"}}
            onChange={handleChange}
            autoHighlight
            getOptionLabel={(option) => option.timezone  ? option.timezone  : "Asia/Kolkata" }
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box
                  key={option.timezone}
                  value={option.timezone}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...optionProps}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=""
                  />
                  {option.timezone}
                </Box>
              );
            }}
            ListboxComponent={(props) => (
              <Box
                {...props}
                sx={{
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              />
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a Timezone"
                onChange={handleSearchChange}
                slotProps={{
                  htmlInput: {
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", // Remove border
                    },
                  },
                }}
              />
            )}
          />
        </div>

        {/* Navbar */}
        <Navbar />
      </Box>
    </Container>
  );
}
