import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const StateProvider = ({ children }) => {
    const [state, setState] = useState(0);
    const [timeDetails , setTImeDetails] = useState({})
    return (
        <StateContext.Provider value={{ state, setState ,timeDetails , setTImeDetails }}>
            {children}
        </StateContext.Provider>
    );
};

export const useTabState = () => useContext(StateContext);