export const countries =[
  { "code": "IN", "label": "India", "flag": "IN", "timezone": "Asia/Kolkata" },
  { "code": "CI", "label": "Ivory Coast", "flag": "CI", "timezone": "Africa/Abidjan" },
  { "code": "GH", "label": "Ghana", "flag": "GH", "timezone": "Africa/Accra" },
  { "code": "ET", "label": "Ethiopia", "flag": "ET", "timezone": "Africa/Addis_Ababa" },
  { "code": "DZ", "label": "Algeria", "flag": "DZ", "timezone": "Africa/Algiers" },
  { "code": "ER", "label": "Eritrea", "flag": "ER", "timezone": "Africa/Asmara" },
  { "code": "ML", "label": "Mali", "flag": "ML", "timezone": "Africa/Bamako" },
  { "code": "CF", "label": "Central African Republic", "flag": "CF", "timezone": "Africa/Bangui" },
  { "code": "GM", "label": "Gambia", "flag": "GM", "timezone": "Africa/Banjul" },
  { "code": "GW", "label": "Guinea-Bissau", "flag": "GW", "timezone": "Africa/Bissau" },
  { "code": "MW", "label": "Malawi", "flag": "MW", "timezone": "Africa/Blantyre" },
  { "code": "CG", "label": "Congo", "flag": "CG", "timezone": "Africa/Brazzaville" },
  { "code": "BI", "label": "Burundi", "flag": "BI", "timezone": "Africa/Bujumbura" },
  { "code": "EG", "label": "Egypt", "flag": "EG", "timezone": "Africa/Cairo" },
  { "code": "MA", "label": "Morocco", "flag": "MA", "timezone": "Africa/Casablanca" },
  { "code": "ES", "label": "Spain", "flag": "ES", "timezone": "Africa/Ceuta" },
  { "code": "GN", "label": "Guinea", "flag": "GN", "timezone": "Africa/Conakry" },
  { "code": "SN", "label": "Senegal", "flag": "SN", "timezone": "Africa/Dakar" },
  { "code": "TZ", "label": "Tanzania", "flag": "TZ", "timezone": "Africa/Dar_es_Salaam" },
  { "code": "DJ", "label": "Djibouti", "flag": "DJ", "timezone": "Africa/Djibouti" },
  { "code": "CM", "label": "Cameroon", "flag": "CM", "timezone": "Africa/Douala" },
  { "code": "EH", "label": "Western Sahara", "flag": "EH", "timezone": "Africa/El_Aaiun" },
  { "code": "SL", "label": "Sierra Leone", "flag": "SL", "timezone": "Africa/Freetown" },
  { "code": "BW", "label": "Botswana", "flag": "BW", "timezone": "Africa/Gaborone" },
  { "code": "ZW", "label": "Zimbabwe", "flag": "ZW", "timezone": "Africa/Harare" },
  { "code": "ZA", "label": "South Africa", "flag": "ZA", "timezone": "Africa/Johannesburg" },
  { "code": "SS", "label": "South Sudan", "flag": "SS", "timezone": "Africa/Juba" },
  { "code": "UG", "label": "Uganda", "flag": "UG", "timezone": "Africa/Kampala" },
  { "code": "SD", "label": "Sudan", "flag": "SD", "timezone": "Africa/Khartoum" },
  { "code": "RW", "label": "Rwanda", "flag": "RW", "timezone": "Africa/Kigali" },
  { "code": "CD", "label": "Democratic Republic of Congo", "flag": "CD", "timezone": "Africa/Kinshasa" },
  { "code": "NG", "label": "Nigeria", "flag": "NG", "timezone": "Africa/Lagos" },
  { "code": "GA", "label": "Gabon", "flag": "GA", "timezone": "Africa/Libreville" },
  { "code": "TG", "label": "Togo", "flag": "TG", "timezone": "Africa/Lome" },
  { "code": "AO", "label": "Angola", "flag": "AO", "timezone": "Africa/Luanda" },
  { "code": "CD", "label": "Congo (Kinshasa)", "flag": "CD", "timezone": "Africa/Lubumbashi" },
  { "code": "ZM", "label": "Zambia", "flag": "ZM", "timezone": "Africa/Lusaka" },
  { "code": "GQ", "label": "Equatorial Guinea", "flag": "GQ", "timezone": "Africa/Malabo" },
  { "code": "MZ", "label": "Mozambique", "flag": "MZ", "timezone": "Africa/Maputo" },
  { "code": "LS", "label": "Lesotho", "flag": "LS", "timezone": "Africa/Maseru" },
  { "code": "SZ", "label": "Eswatini", "flag": "SZ", "timezone": "Africa/Mbabane" },
  { "code": "SO", "label": "Somalia", "flag": "SO", "timezone": "Africa/Mogadishu" },
  { "code": "LR", "label": "Liberia", "flag": "LR", "timezone": "Africa/Monrovia" },
  { "code": "KE", "label": "Kenya", "flag": "KE", "timezone": "Africa/Nairobi" },
  { "code": "TD", "label": "Chad", "flag": "TD", "timezone": "Africa/Ndjamena" },
  { "code": "NE", "label": "Niger", "flag": "NE", "timezone": "Africa/Niamey" },
  { "code": "MR", "label": "Mauritania", "flag": "MR", "timezone": "Africa/Nouakchott" },
  { "code": "BF", "label": "Burkina Faso", "flag": "BF", "timezone": "Africa/Ouagadougou" },
  { "code": "BJ", "label": "Benin", "flag": "BJ", "timezone": "Africa/Porto-Novo" },
  { "code": "ST", "label": "Sao Tome and Principe", "flag": "ST", "timezone": "Africa/Sao_Tome" },
  { "code": "LY", "label": "Libya", "flag": "LY", "timezone": "Africa/Tripoli" },
  { "code": "TN", "label": "Tunisia", "flag": "TN", "timezone": "Africa/Tunis" },
  { "code": "NA", "label": "Namibia", "flag": "NA", "timezone": "Africa/Windhoek" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Adak" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Anchorage" },
  { "code": "AI", "label": "Anguilla", "flag": "AI", "timezone": "America/Anguilla" },
  { "code": "AG", "label": "Antigua and Barbuda", "flag": "AG", "timezone": "America/Antigua" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Araguaina" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/Buenos_Aires" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/Catamarca" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/Cordoba" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/Jujuy" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/La_Rioja" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/Mendoza" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/Rio_Gallegos" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/Salta" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/San_Juan" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/San_Luis" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/Tucuman" },
  { "code": "AR", "label": "Argentina", "flag": "AR", "timezone": "America/Argentina/Ushuaia" },
  { "code": "AW", "label": "Aruba", "flag": "AW", "timezone": "America/Aruba" },
  { "code": "PY", "label": "Paraguay", "flag": "PY", "timezone": "America/Asuncion" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Atikokan" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Bahia" },
  { "code": "MX", "label": "Mexico", "flag": "MX", "timezone": "America/Bahia_Banderas" },
  { "code": "BB", "label": "Barbados", "flag": "BB", "timezone": "America/Barbados" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Belem" },
  { "code": "BZ", "label": "Belize", "flag": "BZ", "timezone": "America/Belize" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Blanc-Sablon" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Boa_Vista" },
  { "code": "CO", "label": "Colombia", "flag": "CO", "timezone": "America/Bogota" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Boise" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Cambridge_Bay" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Campo_Grande" },
  { "code": "MX", "label": "Mexico", "flag": "MX", "timezone": "America/Cancun" },
  { "code": "VE", "label": "Venezuela", "flag": "VE", "timezone": "America/Caracas" },
  { "code": "GF", "label": "French Guiana", "flag": "GF", "timezone": "America/Cayenne" },
  { "code": "KY", "label": "Cayman Islands", "flag": "KY", "timezone": "America/Cayman" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Chicago" },
  { "code": "MX", "label": "Mexico", "flag": "MX", "timezone": "America/Chihuahua" },
  { "code": "CR", "label": "Costa Rica", "flag": "CR", "timezone": "America/Costa_Rica" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Creston" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Cuiaba" },
  { "code": "CW", "label": "Curaçao", "flag": "CW", "timezone": "America/Curacao" },
  { "code": "GL", "label": "Greenland", "flag": "GL", "timezone": "America/Danmarkshavn" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Dawson" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Dawson_Creek" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Denver" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Detroit" },
  { "code": "DM", "label": "Dominica", "flag": "DM", "timezone": "America/Dominica" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Edmonton" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Eirunepe" },
  { "code": "SV", "label": "El Salvador", "flag": "SV", "timezone": "America/El_Salvador" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Fort_Nelson" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Fortaleza" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Glace_Bay" },
  { "code": "GL", "label": "Greenland", "flag": "GL", "timezone": "America/Godthab" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Goose_Bay" },
  { "code": "TC", "label": "Turks and Caicos Islands", "flag": "TC", "timezone": "America/Grand_Turk" },
  { "code": "GD", "label": "Grenada", "flag": "GD", "timezone": "America/Grenada" },
  { "code": "GP", "label": "Guadeloupe", "flag": "GP", "timezone": "America/Guadeloupe" },
  { "code": "GT", "label": "Guatemala", "flag": "GT", "timezone": "America/Guatemala" },
  { "code": "EC", "label": "Ecuador", "flag": "EC", "timezone": "America/Guayaquil" },
  { "code": "GY", "label": "Guyana", "flag": "GY", "timezone": "America/Guyana" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Halifax" },
  { "code": "CU", "label": "Cuba", "flag": "CU", "timezone": "America/Havana" },
  { "code": "MX", "label": "Mexico", "flag": "MX", "timezone": "America/Hermosillo" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Indiana/Indianapolis" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Indiana/Knox" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Indiana/Marengo" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Indiana/Petersburg" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Indiana/Tell_City" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Indiana/Vevay" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Indiana/Vincennes" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Indiana/Winamac" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Inuvik" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Iqaluit" },
  { "code": "JM", "label": "Jamaica", "flag": "JM", "timezone": "America/Jamaica" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Juneau" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Kentucky/Louisville" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Kentucky/Monticello" },
  { "code": "BQ", "label": "Bonaire, Sint Eustatius, and Saba", "flag": "BQ", "timezone": "America/Kralendijk" },
  { "code": "BO", "label": "Bolivia", "flag": "BO", "timezone": "America/La_Paz" },
  { "code": "PE", "label": "Peru", "flag": "PE", "timezone": "America/Lima" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Los_Angeles" },
  { "code": "SX", "label": "Sint Maarten", "flag": "SX", "timezone": "America/Lower_Princes" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Maceio" },
  { "code": "NI", "label": "Nicaragua", "flag": "NI", "timezone": "America/Managua" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Manaus" },
  { "code": "MF", "label": "Saint Martin (French part)", "flag": "MF", "timezone": "America/Marigot" },
  { "code": "MQ", "label": "Martinique", "flag": "MQ", "timezone": "America/Martinique" },
  { "code": "MX", "label": "Mexico", "flag": "MX", "timezone": "America/Matamoros" },
  { "code": "MX", "label": "Mexico", "flag": "MX", "timezone": "America/Mazatlan" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Menominee" },
  { "code": "MX", "label": "Mexico", "flag": "MX", "timezone": "America/Merida" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Metlakatla" },
  { "code": "MX", "label": "Mexico", "flag": "MX", "timezone": "America/Mexico_City" },
  { "code": "PM", "label": "Saint Pierre and Miquelon", "flag": "PM", "timezone": "America/Miquelon" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Moncton" },
  { "code": "MX", "label": "Mexico", "flag": "MX", "timezone": "America/Monterrey" },
  { "code": "UY", "label": "Uruguay", "flag": "UY", "timezone": "America/Montevideo" },
  { "code": "MS", "label": "Montserrat", "flag": "MS", "timezone": "America/Montserrat" },
  { "code": "BS", "label": "Bahamas", "flag": "BS", "timezone": "America/Nassau" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/New_York" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Nipigon" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Nome" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Noronha" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/North_Dakota/Beulah" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/North_Dakota/Center" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/North_Dakota/New_Salem" },
  { "code": "GL", "label": "Greenland", "flag": "GL", "timezone": "America/Nuuk" },
  { "code": "MX", "label": "Mexico", "flag": "MX", "timezone": "America/Ojinaga" },
  { "code": "PA", "label": "Panama", "flag": "PA", "timezone": "America/Panama" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Pangnirtung" },
  { "code": "SR", "label": "Suriname", "flag": "SR", "timezone": "America/Paramaribo" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "America/Phoenix" },
  { "code": "HT", "label": "Haiti", "flag": "HT", "timezone": "America/Port-au-Prince" },
  { "code": "TT", "label": "Trinidad and Tobago", "flag": "TT", "timezone": "America/Port_of_Spain" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Porto_Velho" },
  { "code": "PR", "label": "Puerto Rico", "flag": "PR", "timezone": "America/Puerto_Rico" },
  { "code": "CL", "label": "Chile", "flag": "CL", "timezone": "America/Punta_Arenas" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Rainy_River" },
  { "code": "CA", "label": "Canada", "flag": "CA", "timezone": "America/Rankin_Inlet" },
  { "code": "BR", "label": "Brazil", "flag": "BR", "timezone": "America/Recife" },
  { "code": "SY", "label": "Syria", "flag": "SY", "timezone": "Asia/Damascus" },
  { "code": "BD", "label": "Bangladesh", "flag": "BD", "timezone": "Asia/Dhaka" },
  { "code": "TL", "label": "East Timor", "flag": "TL", "timezone": "Asia/Dili" },
  { "code": "AE", "label": "United Arab Emirates", "flag": "AE", "timezone": "Asia/Dubai" },
  { "code": "TJ", "label": "Tajikistan", "flag": "TJ", "timezone": "Asia/Dushanbe" },
  { "code": "CY", "label": "Cyprus", "flag": "CY", "timezone": "Asia/Famagusta" },
  { "code": "PS", "label": "Palestinian Territories", "flag": "PS", "timezone": "Asia/Gaza" },
  { "code": "PS", "label": "Palestinian Territories", "flag": "PS", "timezone": "Asia/Hebron" },
  { "code": "VN", "label": "Vietnam", "flag": "VN", "timezone": "Asia/Ho_Chi_Minh" },
  { "code": "HK", "label": "Hong Kong", "flag": "HK", "timezone": "Asia/Hong_Kong" },
  { "code": "MN", "label": "Mongolia", "flag": "MN", "timezone": "Asia/Hovd" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Irkutsk" },
  { "code": "ID", "label": "Indonesia", "flag": "ID", "timezone": "Asia/Jakarta" },
  { "code": "ID", "label": "Indonesia", "flag": "ID", "timezone": "Asia/Jayapura" },
  { "code": "IL", "label": "Israel", "flag": "IL", "timezone": "Asia/Jerusalem" },
  { "code": "AF", "label": "Afghanistan", "flag": "AF", "timezone": "Asia/Kabul" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Kamchatka" },
  { "code": "PK", "label": "Pakistan", "flag": "PK", "timezone": "Asia/Karachi" },
  { "code": "NP", "label": "Nepal", "flag": "NP", "timezone": "Asia/Kathmandu" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Khandyga" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Krasnoyarsk" },
  { "code": "MY", "label": "Malaysia", "flag": "MY", "timezone": "Asia/Kuala_Lumpur" },
  { "code": "MY", "label": "Malaysia", "flag": "MY", "timezone": "Asia/Kuching" },
  { "code": "MO", "label": "Macau", "flag": "MO", "timezone": "Asia/Macau" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Magadan" },
  { "code": "ID", "label": "Indonesia", "flag": "ID", "timezone": "Asia/Makassar" },
  { "code": "PH", "label": "Philippines", "flag": "PH", "timezone": "Asia/Manila" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Novokuznetsk" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Novosibirsk" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Omsk" },
  { "code": "KZ", "label": "Kazakhstan", "flag": "KZ", "timezone": "Asia/Oral" },
  { "code": "KH", "label": "Cambodia", "flag": "KH", "timezone": "Asia/Phnom_Penh" },
  { "code": "ID", "label": "Indonesia", "flag": "ID", "timezone": "Asia/Pontianak" },
  { "code": "KP", "label": "North Korea", "flag": "KP", "timezone": "Asia/Pyongyang" },
  { "code": "QA", "label": "Qatar", "flag": "QA", "timezone": "Asia/Qatar" },
  { "code": "KZ", "label": "Kazakhstan", "flag": "KZ", "timezone": "Asia/Qostanay" },
  { "code": "KZ", "label": "Kazakhstan", "flag": "KZ", "timezone": "Asia/Qyzylorda" },
  { "code": "SA", "label": "Saudi Arabia", "flag": "SA", "timezone": "Asia/Riyadh" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Sakhalin" },
  { "code": "UZ", "label": "Uzbekistan", "flag": "UZ", "timezone": "Asia/Samarkand" },
  { "code": "KR", "label": "South Korea", "flag": "KR", "timezone": "Asia/Seoul" },
  { "code": "CN", "label": "China", "flag": "CN", "timezone": "Asia/Shanghai" },
  { "code": "SG", "label": "Singapore", "flag": "SG", "timezone": "Asia/Singapore" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Srednekolymsk" },
  { "code": "TW", "label": "Taiwan", "flag": "TW", "timezone": "Asia/Taipei" },
  { "code": "UZ", "label": "Uzbekistan", "flag": "UZ", "timezone": "Asia/Tashkent" },
  { "code": "GE", "label": "Georgia", "flag": "GE", "timezone": "Asia/Tbilisi" },
  { "code": "IR", "label": "Iran", "flag": "IR", "timezone": "Asia/Tehran" },
  { "code": "BT", "label": "Bhutan", "flag": "BT", "timezone": "Asia/Thimphu" },
  { "code": "JP", "label": "Japan", "flag": "JP", "timezone": "Asia/Tokyo" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Tomsk" },
  { "code": "MN", "label": "Mongolia", "flag": "MN", "timezone": "Asia/Ulaanbaatar" },
  { "code": "CN", "label": "China", "flag": "CN", "timezone": "Asia/Urumqi" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Ust-Nera" },
  { "code": "LA", "label": "Laos", "flag": "LA", "timezone": "Asia/Vientiane" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Vladivostok" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Yakutsk" },
  { "code": "MM", "label": "Myanmar", "flag": "MM", "timezone": "Asia/Yangon" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Asia/Yekaterinburg" },
  { "code": "AM", "label": "Armenia", "flag": "AM", "timezone": "Asia/Yerevan" },
  { "code": "PT", "label": "Portugal", "flag": "PT", "timezone": "Atlantic/Azores" },
  { "code": "BM", "label": "Bermuda", "flag": "BM", "timezone": "Atlantic/Bermuda" },
  { "code": "ES", "label": "Spain", "flag": "ES", "timezone": "Atlantic/Canary" },
  { "code": "CV", "label": "Cape Verde", "flag": "CV", "timezone": "Atlantic/Cape_Verde" },
  { "code": "FO", "label": "Faroe Islands", "flag": "FO", "timezone": "Atlantic/Faroe" },
  { "code": "PT", "label": "Portugal", "flag": "PT", "timezone": "Atlantic/Madeira" },
  { "code": "IS", "label": "Iceland", "flag": "IS", "timezone": "Atlantic/Reykjavik" },
  { "code": "GS", "label": "South Georgia", "flag": "GS", "timezone": "Atlantic/South_Georgia" },
  { "code": "SH", "label": "Saint Helena", "flag": "SH", "timezone": "Atlantic/St_Helena" },
  { "code": "FK", "label": "Falkland Islands", "flag": "FK", "timezone": "Atlantic/Stanley" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Adelaide" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Brisbane" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Broken_Hill" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Currie" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Darwin" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Eucla" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Hobart" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Lindeman" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Lord_Howe" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Melbourne" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Perth" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Australia/Sydney" },
  { "code": "NL", "label": "Netherlands", "flag": "NL", "timezone": "Europe/Amsterdam" },
  { "code": "AD", "label": "Andorra", "flag": "AD", "timezone": "Europe/Andorra" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Europe/Astrakhan" },
  { "code": "GR", "label": "Greece", "flag": "GR", "timezone": "Europe/Athens" },
  { "code": "RS", "label": "Serbia", "flag": "RS", "timezone": "Europe/Belgrade" },
  { "code": "DE", "label": "Germany", "flag": "DE", "timezone": "Europe/Berlin" },
  { "code": "SK", "label": "Slovakia", "flag": "SK", "timezone": "Europe/Bratislava" },
  { "code": "BE", "label": "Belgium", "flag": "BE", "timezone": "Europe/Brussels" },
  { "code": "RO", "label": "Romania", "flag": "RO", "timezone": "Europe/Bucharest" },
  { "code": "HU", "label": "Hungary", "flag": "HU", "timezone": "Europe/Budapest" },
  { "code": "DE", "label": "Germany", "flag": "DE", "timezone": "Europe/Busingen" },
  { "code": "MD", "label": "Moldova", "flag": "MD", "timezone": "Europe/Chisinau" },
  { "code": "DK", "label": "Denmark", "flag": "DK", "timezone": "Europe/Copenhagen" },
  { "code": "IE", "label": "Ireland", "flag": "IE", "timezone": "Europe/Dublin" },
  { "code": "GI", "label": "Gibraltar", "flag": "GI", "timezone": "Europe/Gibraltar" },
  { "code": "GG", "label": "Guernsey", "flag": "GG", "timezone": "Europe/Guernsey" },
  { "code": "FI", "label": "Finland", "flag": "FI", "timezone": "Europe/Helsinki" },
  { "code": "IM", "label": "Isle of Man", "flag": "IM", "timezone": "Europe/Isle_of_Man" },
  { "code": "TR", "label": "Turkey", "flag": "TR", "timezone": "Europe/Istanbul" },
  { "code": "JE", "label": "Jersey", "flag": "JE", "timezone": "Europe/Jersey" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Europe/Kaliningrad" },
  { "code": "UA", "label": "Ukraine", "flag": "UA", "timezone": "Europe/Kiev" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Europe/Kirov" },
  { "code": "PT", "label": "Portugal", "flag": "PT", "timezone": "Europe/Lisbon" },
  { "code": "SI", "label": "Slovenia", "flag": "SI", "timezone": "Europe/Ljubljana" },
  { "code": "GB", "label": "United Kingdom", "flag": "GB", "timezone": "Europe/London" },
  { "code": "LU", "label": "Luxembourg", "flag": "LU", "timezone": "Europe/Luxembourg" },
  { "code": "ES", "label": "Spain", "flag": "ES", "timezone": "Europe/Madrid" },
  { "code": "MT", "label": "Malta", "flag": "MT", "timezone": "Europe/Malta" },
  { "code": "AX", "label": "Aland Islands", "flag": "AX", "timezone": "Europe/Mariehamn" },
  { "code": "BY", "label": "Belarus", "flag": "BY", "timezone": "Europe/Minsk" },
  { "code": "MC", "label": "Monaco", "flag": "MC", "timezone": "Europe/Monaco" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Europe/Moscow" },
  { "code": "NO", "label": "Norway", "flag": "NO", "timezone": "Europe/Oslo" },
  { "code": "FR", "label": "France", "flag": "FR", "timezone": "Europe/Paris" },
  { "code": "ME", "label": "Montenegro", "flag": "ME", "timezone": "Europe/Podgorica" },
  { "code": "CZ", "label": "Czech Republic", "flag": "CZ", "timezone": "Europe/Prague" },
  { "code": "LV", "label": "Latvia", "flag": "LV", "timezone": "Europe/Riga" },
  { "code": "IT", "label": "Italy", "flag": "IT", "timezone": "Europe/Rome" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Europe/Samara" },
  { "code": "SM", "label": "San Marino", "flag": "SM", "timezone": "Europe/San_Marino" },
  { "code": "BA", "label": "Bosnia and Herzegovina", "flag": "BA", "timezone": "Europe/Sarajevo" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Europe/Saratov" },
  { "code": "UA", "label": "Ukraine", "flag": "UA", "timezone": "Europe/Simferopol" },
  { "code": "MK", "label": "North Macedonia", "flag": "MK", "timezone": "Europe/Skopje" },
  { "code": "BG", "label": "Bulgaria", "flag": "BG", "timezone": "Europe/Sofia" },
  { "code": "SE", "label": "Sweden", "flag": "SE", "timezone": "Europe/Stockholm" },
  { "code": "EE", "label": "Estonia", "flag": "EE", "timezone": "Europe/Tallinn" },
  { "code": "AL", "label": "Albania", "flag": "AL", "timezone": "Europe/Tirane" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Europe/Ulyanovsk" },
  { "code": "UA", "label": "Ukraine", "flag": "UA", "timezone": "Europe/Uzhgorod" },
  { "code": "LI", "label": "Liechtenstein", "flag": "LI", "timezone": "Europe/Vaduz" },
  { "code": "VA", "label": "Vatican City", "flag": "VA", "timezone": "Europe/Vatican" },
  { "code": "AT", "label": "Austria", "flag": "AT", "timezone": "Europe/Vienna" },
  { "code": "LT", "label": "Lithuania", "flag": "LT", "timezone": "Europe/Vilnius" },
  { "code": "RU", "label": "Russia", "flag": "RU", "timezone": "Europe/Volgograd" },
  { "code": "PL", "label": "Poland", "flag": "PL", "timezone": "Europe/Warsaw" },
  { "code": "HR", "label": "Croatia", "flag": "HR", "timezone": "Europe/Zagreb" },
  { "code": "UA", "label": "Ukraine", "flag": "UA", "timezone": "Europe/Zaporozhye" },
  { "code": "CH", "label": "Switzerland", "flag": "CH", "timezone": "Europe/Zurich" },
  { "code": "MG", "label": "Madagascar", "flag": "MG", "timezone": "Indian/Antananarivo" },
  { "code": "IO", "label": "British Indian Ocean Territory", "flag": "IO", "timezone": "Indian/Chagos" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Indian/Christmas" },
  { "code": "CC", "label": "Cocos (Keeling) Islands", "flag": "CC", "timezone": "Indian/Cocos" },
  { "code": "KM", "label": "Comoros", "flag": "KM", "timezone": "Indian/Comoro" },
  { "code": "TF", "label": "French Southern Territories", "flag": "TF", "timezone": "Indian/Kerguelen" },
  { "code": "SC", "label": "Seychelles", "flag": "SC", "timezone": "Indian/Mahe" },
  { "code": "MV", "label": "Maldives", "flag": "MV", "timezone": "Indian/Maldives" },
  { "code": "MU", "label": "Mauritius", "flag": "MU", "timezone": "Indian/Mauritius" },
  { "code": "YT", "label": "Mayotte", "flag": "YT", "timezone": "Indian/Mayotte" },
  { "code": "RE", "label": "Réunion", "flag": "RE", "timezone": "Indian/Reunion" },
  { "code": "WS", "label": "Samoa", "flag": "WS", "timezone": "Pacific/Apia" },
  { "code": "NZ", "label": "New Zealand", "flag": "NZ", "timezone": "Pacific/Auckland" },
  { "code": "PG", "label": "Papua New Guinea", "flag": "PG", "timezone": "Pacific/Bougainville" },
  { "code": "NZ", "label": "New Zealand", "flag": "NZ", "timezone": "Pacific/Chatham" },
  { "code": "FM", "label": "Federated States of Micronesia", "flag": "FM", "timezone": "Pacific/Chuuk" },
  { "code": "CL", "label": "Chile", "flag": "CL", "timezone": "Pacific/Easter" },
  { "code": "VU", "label": "Vanuatu", "flag": "VU", "timezone": "Pacific/Efate" },
  { "code": "KI", "label": "Kiribati", "flag": "KI", "timezone": "Pacific/Enderbury" },
  { "code": "TK", "label": "Tokelau", "flag": "TK", "timezone": "Pacific/Fakaofo" },
  { "code": "FJ", "label": "Fiji", "flag": "FJ", "timezone": "Pacific/Fiji" },
  { "code": "TV", "label": "Tuvalu", "flag": "TV", "timezone": "Pacific/Funafuti" },
  { "code": "EC", "label": "Ecuador", "flag": "EC", "timezone": "Pacific/Galapagos" },
  { "code": "PF", "label": "French Polynesia", "flag": "PF", "timezone": "Pacific/Gambier" },
  { "code": "SB", "label": "Solomon Islands", "flag": "SB", "timezone": "Pacific/Guadalcanal" },
  { "code": "GU", "label": "Guam", "flag": "GU", "timezone": "Pacific/Guam" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "Pacific/Honolulu" },
  { "code": "KI", "label": "Kiribati", "flag": "KI", "timezone": "Pacific/Kiritimati" },
  { "code": "FM", "label": "Federated States of Micronesia", "flag": "FM", "timezone": "Pacific/Kosrae" },
  { "code": "MH", "label": "Marshall Islands", "flag": "MH", "timezone": "Pacific/Kwajalein" },
  { "code": "MH", "label": "Marshall Islands", "flag": "MH", "timezone": "Pacific/Majuro" },
  { "code": "PF", "label": "French Polynesia", "flag": "PF", "timezone": "Pacific/Marquesas" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "Pacific/Midway" },
  { "code": "NR", "label": "Nauru", "flag": "NR", "timezone": "Pacific/Nauru" },
  { "code": "NU", "label": "Niue", "flag": "NU", "timezone": "Pacific/Niue" },
  { "code": "AU", "label": "Australia", "flag": "AU", "timezone": "Pacific/Norfolk" },
  { "code": "NC", "label": "New Caledonia", "flag": "NC", "timezone": "Pacific/Noumea" },
  { "code": "AS", "label": "American Samoa", "flag": "AS", "timezone": "Pacific/Pago_Pago" },
  { "code": "PW", "label": "Palau", "flag": "PW", "timezone": "Pacific/Palau" },
  { "code": "PN", "label": "Pitcairn Islands", "flag": "PN", "timezone": "Pacific/Pitcairn" },
  { "code": "FM", "label": "Federated States of Micronesia", "flag": "FM", "timezone": "Pacific/Pohnpei" },
  { "code": "PG", "label": "Papua New Guinea", "flag": "PG", "timezone": "Pacific/Port_Moresby" },
  { "code": "CK", "label": "Cook Islands", "flag": "CK", "timezone": "Pacific/Rarotonga" },
  { "code": "PF", "label": "French Polynesia", "flag": "PF", "timezone": "Pacific/Tahiti" },
  { "code": "KI", "label": "Kiribati", "flag": "KI", "timezone": "Pacific/Tarawa" },
  { "code": "TO", "label": "Tonga", "flag": "TO", "timezone": "Pacific/Tongatapu" },
  { "code": "US", "label": "United States", "flag": "US", "timezone": "Pacific/Wake" },
  { "code": "WF", "label": "Wallis and Futuna", "flag": "WF", "timezone": "Pacific/Wallis" }
]
